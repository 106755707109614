<!-- =========================================================================================
    File Name: Main.vue
    Description: Main layout
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div>
    <div class="layout--main" :class="[layoutTypeClass, navbarClasses, footerClasses, {'no-scroll': isAppPage}]">

      <v-nav-menu
        :navMenuItems = "navMenuItems"
        title         = "ShipBlu"
        parent        = ".layout--main" />
      <div id="content-area" :class="[contentAreaClass, {'show-overlay': bodyOverlay}]">
        <div id="content-overlay" />

      <the-navbar-vertical
        :navbarColor="navbarColor"
        :class="[
          {'text-white' : isNavbarDark  && !isThemeDark},
          {'text-base'  : !isNavbarDark && isThemeDark}
        ]" />

        <div class="content-wrapper">

          <div class="router-view">
            <div class="router-content">

              <transition :name="routerTransition">

                <div v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="router-header flex flex-wrap items-center mb-6">
                  <div
                    v-if="routeTitle"
                    class="content-area__heading mr-4"
                    :class="{'pr-4 border-0 md:border-r border-solid border-grey-light' : $route.meta.breadcrumb}">
                    <h2 class="mb-1">{{ $t(routeTitle) }}</h2>
                  </div>

                  <!-- BREADCRUMB -->
                  <vx-breadcrumb class="md:block hidden" :store="$store" :i18nData="i18nData" v-if="$route.meta.breadcrumb" :route="$route" :lang="$i18n.locale" :isRTL="$vs.rtl" />
                  
                </div>
              </transition>

              <div class="content-area__content">
                <div class="back-to-top hidden" :class="userHasScrolled ? 'show-scroll' : 'hide-scroll'">
                  <vs-button @click="toTop" icon-pack="feather" icon="icon-arrow-up" class="shadow-lg btn-back-to-top" />
                </div>
                <vs-alert v-if="!isOnline" icon-pack="feather" icon="icon-alert-circle" color="warning" class="mb-base">{{$t('App is running in offline mode.')}}</vs-alert>
                <transition :name="routerTransition" mode="out-in">
                  <router-view @changeRouteTitle="changeRouteTitle" @setAppClasses="(classesStr) => $emit('setAppClasses', classesStr)" />
                </transition>
              </div>
            </div>
          </div>
        </div>
        <the-footer />
      </div>
    </div>
  </div>
</template>


<script>
import TheNavbarVertical   from '@/layouts/fulfillment/navbar/TheNavbarVertical.vue'
import TheFooter           from '@/layouts/components/TheFooter.vue'
import themeConfig         from '@/../themeConfig.js'
import VNavMenu            from '@/layouts/fulfillment/VerticalNavMenu.vue'
import i18nData            from '../../i18n/i18nData.js'
import { sendFulfillmentRequest }  from '../../http/axios/requestHelper.js'
import VueJwtDecode from 'vue-jwt-decode'

export default {
  components: {
    TheFooter,
    TheNavbarVertical,
    VNavMenu
  },
  data () {
    return {
      navMenuItems      : [],
      dynamicWatchers   : {},
      footerType        : themeConfig.footerType  || 'static',
      hideScrollToTop   : themeConfig.hideScrollToTop,
      isNavbarDark      : false,
      navbarColor       : themeConfig.navbarColor || '#fff',
      navbarType        : themeConfig.navbarType  || 'floating',
      routerTransition  : themeConfig.routerTransition || 'none',
      routeTitle        : this.$route.meta.pageTitle,
      i18nData,
      userHasScrolled   : false,
      stockRequestsCount              : localStorage.getItem('createdStockRequests'),
      receivedStockRequestCount: localStorage.getItem('receivedStockRequests'),
      inboundedStockRequestCount: localStorage.getItem('inboundedStockRequest')
    }
  },
  watch: {
    '$route' () {
      this.routeTitle = this.$route.meta.pageTitle
    }
  },
  computed: {
    bodyOverlay () { return this.$store.state.bodyOverlay },
    contentAreaClass () {
      if (this.mainLayoutType === 'vertical') {
        if      (this.verticalNavMenuWidth === 'default') return 'content-area-reduced'
        else if (this.verticalNavMenuWidth === 'reduced') return 'content-area-lg'
      }
    },
    footerClasses () {
      return {
        'footer-hidden': this.footerType === 'hidden',
        'footer-sticky': this.footerType === 'sticky',
        'footer-static': this.footerType === 'static'
      }
    },
    isAppPage () {
      return this.$route.meta.no_scroll
    },
    isThemeDark ()     { return this.$store.state.theme === 'dark' },
    layoutTypeClass () { return `main-${this.mainLayoutType}`      },
    mainLayoutType ()  { return this.$store.state.mainLayoutType   },
    navbarClasses ()   {
      return {
        'navbar-hidden'   : this.navbarType === 'hidden',
        'navbar-sticky'   : this.navbarType === 'sticky',
        'navbar-static'   : this.navbarType === 'static',
        'navbar-floating' : this.navbarType === 'floating'
      }
    },
    verticalNavMenuWidth () { return this.$store.state.verticalNavMenuWidth },
    windowWidth ()          { return this.$store.state.windowWidth }
  },
  methods: {
    toTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    changeRouteTitle (title) {
      this.routeTitle = title
    },
    toggleHideScrollToTop (val) {
      this.hideScrollToTop = val
    },
    loadStockRequests () {
      switch (this.$store.state.AppActiveUser.userRole) {
      case 'fc-inbounding-agent':
        this.loadStockRequestsFilterd('received')
        break
      case 'fc-storing-agent':
        this.loadStockRequestsFilterd('inbounded')
        break
      case 'fc-operations-supervisor':
        this.loadStockRequestsFilterd('submitted')
        break
      case 'fc-receiving-agent':
        this.loadStockRequestsFilterd('submitted')
        break
      default:
        break
      }
    },
    loadStockRequestsFilterd (status) {
      sendFulfillmentRequest(false, false, this, `api/v1/fc/stock-requests/?status=${status}`, 'get', null, true,
        (response) => {
          switch (this.$store.state.AppActiveUser.userRole) {
          case 'fc-inbounding-agent':
            localStorage.setItem('receivedStockRequests', response.data.count)
            this.loadNavMenuInboundingAgent()
            break
          case 'fc-storing-agent':
            localStorage.setItem('inboundedStockRequest', response.data.count)
            this.loadNavMenuStoringAgent()
            break
          case 'fc-operations-supervisor':
            localStorage.setItem('createdStockRequests', response.data.count)
            this.loadNavMenu()
            break
          case 'fc-receiving-agent':
            localStorage.setItem('createdStockRequests', response.data.count)
            this.loadNavMenuReceivingAgent()
            break
          default:
            break
          }
          localStorage.setItem('receivedStockRequests', response.data.count)
          this.loadNavMenuInboundingAgent()  
        }
      )
    },
    loadNavMenu () {
      this.navMenuItems = []
      this.navMenuItems.push(
        {
          url: '/fc-operations-supervisor/dashboard',
          name: 'Dashboard',
          i18n: 'Dashboard',
          icon: 'HomeIcon',
          slug: 'fc-operations-supervisor-dashboard'
        },
        {
          name: 'FC OPS',
          i18n: 'Stock Requests',
          icon: 'ShoppingCartIcon',
          tag: localStorage.getItem('createdStockRequests'),
          tagColor: 'danger',
          submenu: [
            {
              url: `/${this.$route.params.lang}/fc-operations-supervisor/stock-requests`,
              name: 'WROs',
              i18n: 'WROs',
              tag: localStorage.getItem('createdStockRequests'),
              tagColor: 'danger',
              icon: 'ShoppingCartIcon',
              slug: 'fc-operations-supervisor-dashboard'
            },
            {
              url: `/${this.$route.params.lang}/fc-operations-supervisor/unidentified-stock-requests`,
              name: 'UROs',
              i18n: 'UROs',
              icon: 'AlertCircleIcon',
              slug: 'fc-operations-supervisor-dashboard'
            }
          ]
        },
        {
          url: '/fc-operations-supervisor/stock-recalls',
          name: 'Stock Recalls',
          i18n: 'Stock Recalls',
          icon: 'ShoppingCartIcon',
          slug: 'fc-operations-supervisor-stock-recalls'
        },
        {
          url: '/fc-operations-supervisor/inventory',
          name: 'Inventory',
          i18n: 'Inventory',
          icon: 'ListIcon',
          slug: 'fc-operations-supervisor-dashboard'
        },
        {
          url: '/fc-operations-supervisor/fulfillment/outbound-orders',
          name: 'Outbound Orders',
          i18n: 'Outbound Orders',
          icon: 'PackageIcon',
          slug: 'fc-operations-supervisor-outbound-orders'
        },
        {
          url: '/fc-operations-supervisor/fulfillment/inbound-orders',
          name: 'Inbound Orders',
          i18n: 'Inbound Orders',
          icon: 'PackageIcon',
          slug: 'fc-operations-supervisor-inbound-orders'
        },
        {
          url: '/fc-operations-supervisor/bins',
          name: 'Bins',
          i18n: 'bins',
          icon: 'ArchiveIcon',
          slug: 'fc-operations-supervisor-bins'
        },
        {
          name: 'FC OPS',
          i18n: 'Storage Units',
          icon: 'ShoppingCartIcon',
          submenu: [
            {
              url: `/${this.$route.params.lang}/fc-operations-supervisor/totes`,
              name: 'Tote',
              i18n: 'Tote',
              icon: 'BoxIcon',
              slug: 'fc-operations-supervisor-dashboard'
            },
            {
              url: `/${this.$route.params.lang}/fc-operations-supervisor/boxes`,
              name: 'Box',
              i18n: 'Box',
              icon: 'BoxIcon',
              slug: 'fc-operations-supervisor-dashboard'
            },
            {
              url: `/${this.$route.params.lang}/fc-operations-supervisor/pallets`,
              name: 'Pallet',
              i18n: 'Pallet',
              icon: 'BoxIcon',
              slug: 'fc-operations-supervisor-dashboard'
            },
            {
              url: `/${this.$route.params.lang}/fc-operations-supervisor/shelves`,
              name: 'Shelf',
              i18n: 'Shelf',
              icon: 'BoxIcon',
              slug: 'fc-operations-supervisor-dashboard'
            }
          ]
        },
        {
          url: '/fc-operations-supervisor/picking-bins',
          name: 'Picking Bins',
          i18n: 'Picking Bins',
          icon: 'ArchiveIcon',
          slug: 'fc-operations-supervisor-picking-bins'
        },
        {
          url: '/fc-operations-supervisor/task-lists',
          name: 'Task Lists',
          i18n: 'Task Lists',
          icon: 'ArchiveIcon',
          slug: 'fc-operations-supervisor-task-lists'
        }
      )
    },
    loadNavMenuReceivingAgent () {
      this.navMenuItems = []
      this.navMenuItems.push(
        {
          url: '/fc-receiving-agent/dashboard',
          name: 'Dashboard',
          i18n: 'Dashboard',
          icon: 'HomeIcon',
          slug: 'receiving-dashboard'
        },
        {
          name: 'FC RECEIVING',
          i18n: 'Stock Requests',
          icon: 'ShoppingCartIcon',
          tag: this.stockRequestsCount,
          tagColor: 'danger',
          submenu: [
            {
              url: `/${this.$route.params.lang}/fc-receiving-agent/stock-requests`,
              name: 'WROs',
              i18n: 'WROs',
              tag: this.stockRequestsCount,
              tagColor: 'danger',
              icon: 'ShoppingCartIcon',
              slug: 'fc-receiving-agent-dashboard'
            },
            {
              url: `/${this.$route.params.lang}/fc-receiving-agent/unidentified-stock-requests`,
              name: 'UROs',
              i18n: 'UROs',
              icon: 'AlertCircleIcon',
              slug: 'fc-receiving-agent-dashboard'
            }
          ]
        }
      )
    },
    loadNavMenuInboundingAgent () {
      this.navMenuItems = []
      this.navMenuItems.push(
        {
          url: '/fc-inbounding-agent/dashboard',
          name: 'Dashboard',
          i18n: 'Dashboard',
          icon: 'HomeIcon',
          slug: 'inbounding-dashboard'
        },
        {
          name: 'FC INBOUNDING',
          i18n: 'Stock Requests',
          icon: 'ShoppingCartIcon',
          tag: this.receivedStockRequestCount,
          tagColor: 'danger',
          submenu: [
            {
              url: `/${this.$route.params.lang}/fc-inbounding-agent/stock-requests`,
              name: 'WROs',
              i18n: 'WROs',
              tag: this.receivedStockRequestCount,
              tagColor: 'danger',
              icon: 'ShoppingCartIcon',
              slug: 'fc-inbounding-agent-dashboard'
            },
            {
              url: `/${this.$route.params.lang}/fc-inbounding-agent/unidentified-stock-requests`,
              name: 'UROs',
              i18n: 'UROs',
              icon: 'AlertCircleIcon',
              slug: 'fc-inbounding-agent-dashboard'
            }
          ]
        },
        {
          url: '/fc-inbounding-agent/bins',
          name: 'Bins',
          i18n: 'bins',
          icon: 'ArchiveIcon',
          slug: 'fc-inbounding-agent-bins'
        }
      )
    },
    loadNavMenuStoringAgent () {
      this.navMenuItems = []
      this.navMenuItems.push(
        {
          url: '/fc-storing-agent/dashboard',
          name: 'Dashboard',
          i18n: 'Dashboard',
          icon: 'HomeIcon',
          slug: 'storing-dashboard'
        },
        {
          name: 'FC STORING',
          i18n: 'Stock Requests',
          icon: 'ShoppingCartIcon',
          tag: this.inboundedStockRequestCount,
          tagColor: 'danger',
          submenu: [
            {
              url: `/${this.$route.params.lang}/fc-storing-agent/stock-requests`,
              name: 'WROs',
              i18n: 'WROs',
              tag: this.inboundedStockRequestCount,
              tagColor: 'danger',
              icon: 'ShoppingCartIcon',
              slug: 'fc-storing-agent-dashboard'
            },
            {
              url: `/${this.$route.params.lang}/fc-storing-agent/unidentified-stock-requests`,
              name: 'UROs',
              i18n: 'UROs',
              icon: 'AlertCircleIcon',
              slug: 'fc-storing-agent-dashboard'
            }
          ]
        },
        {
          url: '/fc-storing-agent/bins',
          name: 'Bins',
          i18n: 'bins',
          icon: 'ArchiveIcon',
          slug: 'fc-storing-agent-bins'
        },
        {
          name: 'FC STORING',
          i18n: 'Storage Units',
          icon: 'ShoppingCartIcon',
          submenu: [
            {
              url: `/${this.$route.params.lang}/fc-storing-agent/totes`,
              name: 'Tote',
              i18n: 'Tote',
              icon: 'BoxIcon',
              slug: 'fc-storing-agent-dashboard'
            },
            {
              url: `/${this.$route.params.lang}/fc-storing-agent/boxes`,
              name: 'Box',
              i18n: 'Box',
              icon: 'BoxIcon',
              slug: 'fc-storing-agent-dashboard'
            },
            {
              url: `/${this.$route.params.lang}/fc-storing-agent/pallets`,
              name: 'Pallet',
              i18n: 'Pallet',
              icon: 'BoxIcon',
              slug: 'fc-storing-agent-dashboard'
            },
            {
              url: `/${this.$route.params.lang}/fc-storing-agent/shelves`,
              name: 'Shelf',
              i18n: 'Shelf',
              icon: 'BoxIcon',
              slug: 'fc-storing-agent-dashboard'
            }
          ]
        }
      )
    },
    loadNavMenuPackingAgent () {
      this.navMenuItems = []
      this.navMenuItems.push(
        {
          url: '/fc-packing-agent/dashboard',
          name: 'Dashboard',
          i18n: 'Dashboard',
          icon: 'HomeIcon',
          slug: 'packing-dashboard'
        },
        {
          url: '/fc-packing-agent/picking-bins',
          name: 'Picking Bins',
          i18n: 'Picking Bins',
          icon: 'ArchiveIcon',
          slug: 'fc-packing-agent-picking-bins'
        },
        {
          url: '/fc-packing-agent/packing-tasklists',
          name: 'Packing Tasklists',
          i18n: 'Packing Tasklists',
          icon: 'ArchiveIcon',
          slug: 'fc-packing-agent-packing-tasklists'
        }
      )
    },
    loadNavMenuPickingAgent () {
      this.navMenuItems = []
      this.navMenuItems.push(
        {
          url: '/fc-picking-agent/dashboard',
          name: 'Dashboard',
          i18n: 'Dashboard',
          icon: 'HomeIcon',
          slug: 'picking-dashboard'
        },
        {
          url: '/fc-picking-agent/picking-bins',
          name: 'Picking Bins',
          i18n: 'Picking Bins',
          icon: 'ArchiveIcon',
          slug: 'fc-picking-agent-picking-bins'
        },
        {
          url: '/fc-picking-agent/picking-tasklists',
          name: 'Picking Task Lists',
          i18n: 'Picking Task Lists',
          icon: 'ArchiveIcon',
          slug: 'fc-picking-agent-picking-tasklists'
        }
      )
    }
  },
  created () {
    const userInfo = localStorage.getItem('accessToken') ? VueJwtDecode.decode(localStorage.getItem('accessToken')) : {}
    this.$store.dispatch('updateUserInfo', {
      displayName: userInfo.name,
      email: userInfo.email,
      photoURL: userInfo.picture,
      providerId: userInfo.sub.length > 0 ? userInfo.sub.substr(0, userInfo.sub.indexOf('|')) : '',
      uid: userInfo.sub,
      userRole: userInfo[`${process.env.VUE_APP_API_URL}/role`][0]
    })
    this.$acl.change(this.$store.state.AppActiveUser.userRole)
    const query = {
      ...this.$route.query,
      page: Number(this.$route.query.page) > 0 ? this.$route.query.page : 1
    }
    if (this.$route.query && this.$route.query.page < 0) {
      this.$router.push({
        query
      }).catch(() => {})
    }
    localStorage.setItem('multipleWarehouses', true)
    const fcRoles = ['fc-storing-agent', 'fc-inbounding-agent', 'fc-receiving-agent', 'fc-operations-supervisor', 'fc-packing-agent', 'fc-picking-agent']
    if (fcRoles.includes(this.$store.state.AppActiveUser.userRole)) {
      setInterval(() => {
        this.navMenuItems = []
        this.stockRequestsCount = localStorage.getItem('createdStockRequests')
        this.receivedStockRequestCount = localStorage.getItem('receivedStockRequests')
        this.inboundedStockRequestCount = localStorage.getItem('inboundedStockRequest')
        
        switch (this.$store.state.AppActiveUser.userRole) {
        case 'fc-receiving-agent':
          this.loadNavMenuReceivingAgent()
          break
        case 'fc-inbounding-agent':
          this.loadNavMenuInboundingAgent()
          break
        case 'fc-storing-agent':
          this.loadNavMenuStoringAgent()
          break
        case 'fc-packing-agent':
          this.loadNavMenuPackingAgent()
          break
        case 'fc-picking-agent':
          this.loadNavMenuPickingAgent()
          break
        default:
          this.loadNavMenu()
          break
        }
      }, 1000)
      this.loadStockRequests()
    }
    if (this.$cookies.get('language') === 'en') {
      this.$i18n.locale = 'en'
      this.$vs.rtl = false
    } else {
      this.$i18n.locale = 'ar'
      this.$vs.rtl = true
    }
    window.onscroll = () => {
      if (document.documentElement.scrollTop > 300) {
        this.userHasScrolled = true
      } else {
        this.userHasScrolled = false
      }
    }
  }
}

</script>

<style>
iframe[title="Message from company"],
iframe[title="Close message"] {
  display: none !important;
}
#launcher {
  display: none;
}
#fc_frame {
  display: none;
}
.back-to-top {
  position: fixed;
  z-index: 99999;
  bottom: 35px;
  right: 35px;
  transition: 0.5s;
}
.hide-scroll {
  opacity: 0;
}
.show-scroll {
  opacity: 1;
}
</style>
